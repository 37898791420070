export const chFinishOrder = {
  address: "地址",
  amountCart: "购物车",
  amountTip: "小费",
  back: "返回",
  continue: "继续",
  confirmOrder: "确认订单",
  productsQty: "产品数量",
  totalToPay: "支付总额",
  deliveryTime: "Delivery time",
  sooner: "As soon as possible",
  noAvailability: "No turns left",
};
