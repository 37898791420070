import { cartService } from "@/services";

export const shoppingCartCtrl = {
  handleSendOrder: async (order) => {
    const { data } = await cartService.sendOrder({ ...order });
    return data;
  },
  getShiftsTimes: async (params) => {
    const { data } = await cartService.getShiftsTimes(params);
    return data;
  },
};
