import { axiosInstance } from "@/core";
import {
  buildUrl,
  getConfigWithHeaderAuthorizationDefault,
  addAccessTokenToHeaders,
  addQueryParamsBase64ToUrl,
} from "@/helpers";

import { mapperOrderTypeEndpoint } from "@/adapters";
import { orderTypeName } from "@/store/modules/orderType/constNames";

const configForOrderLocal = getConfigWithHeaderAuthorizationDefault;
const config = addAccessTokenToHeaders;

export const cartService = {
  sendOrder: async (order) => {
    const { type } = order;
    const configAxios =
      type === orderTypeName.LOCAL.toLowerCase() ? configForOrderLocal : config;
    return await axiosInstance.post(
      buildUrl(`requests/${mapperOrderTypeEndpoint[type]}`),
      order,
      configAxios()
    );
  },

  getShiftsTimes: async (paramsToConvertBase64) => {
    const url = buildUrl(`bars_hours/domicile_pickup/shifts_times`);
    const urlWithQuery = addQueryParamsBase64ToUrl(url, paramsToConvertBase64);
    return await axiosInstance.get(urlWithQuery, {
      headers: {
        Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
        "Content-Type": "application/json",
        Site: process.env.VUE_APP_SITE,
        Client: "app",
      },
    });
  },
};
