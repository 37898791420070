export const adapterToSettingsDigitalLetter = ({
  allow_tips,
  description_en,
  description_es,
  header_background_color,
  header_image,
  logo,
  minimal_order_domicile,
  page_background_color,
  principal_color,
  subtitle_en,
  subtitle_es,
  title_en,
  title_es,
  bookings,
  call_waiter,
  local_orders,
}) => ({
  allowTips: allow_tips,
  images: { logo, header: header_image },
  minimalOrderDomicile: minimal_order_domicile,
  styles: {
    primaryColor: principal_color,
    bgPageColor: page_background_color,
    bgHeaderColor: header_background_color,
  },
  texts: {
    title: { en: title_en, es: title_es },
    subtitle: { en: subtitle_en, es: subtitle_es },
    description: { en: description_en, es: description_es },
  },
  bookings: bookings,
  callWaiter: call_waiter,
  localOrders: local_orders,
});
