export const getters = {
  GET_ALLOW_TIPS: ({ settingDigitalLetter: { allowTips } }) => allowTips,
  GET_STYLES: ({ settingDigitalLetter: { styles } }) => styles,
  GET_TEXTS: ({ settingDigitalLetter: { texts } }) => texts,
  GET_IMAGES: ({ settingDigitalLetter: { images } }) => images,
  GET_MINIMAL_ORDER_DOMICILE: ({
    settingDigitalLetter: { minimalOrderDomicile },
  }) => minimalOrderDomicile,
  GET_BOOKINGS: ({ settingDigitalLetter: { bookings } }) => bookings,
  GET_CALL_WAITER: ({ settingDigitalLetter: { callWaiter } }) => callWaiter,
  GET_LOCAL_ORDERS: ({ settingDigitalLetter: { localOrders } }) => localOrders,
};
