import { enAggregateCombinedLocale } from "./aggregatesAndCombined";
import { enAuthLocale } from "./auth";
import { enBtnActionsLocale } from "./btnActions";
import { enCheckPaymentLocale } from "./checkPayment";
import { enErrorInputsLocale } from "./errorMessages";
import { enFinishOrder } from "./finishOrder";
import { enHomeLocale } from "./home";
import { enInputsLocale } from "./inputs";
import { enLanguageLocale } from "./languages";
import { enMenuItemLocale } from "./menuItemAppBar";
import { enOrderLocale } from "./order";
import { enOrdersLocale } from "./orders";
import { enReservationLocale } from "./reservation";
import { enTipLocale } from "./tip";
import { enUserAddressLocale } from "./addresses";
import { enUserSettingsLocale } from "./userSettings";
import { enLegalDocuments } from "./legalDocuments";

export default {
  ...enAggregateCombinedLocale,
  ...enAuthLocale,
  ...enBtnActionsLocale,
  ...enCheckPaymentLocale,
  ...enErrorInputsLocale,
  ...enFinishOrder,
  ...enHomeLocale,
  ...enInputsLocale,
  ...enLanguageLocale,
  ...enMenuItemLocale,
  ...enOrderLocale,
  ...enOrdersLocale,
  ...enReservationLocale,
  ...enTipLocale,
  ...enUserAddressLocale,
  ...enUserSettingsLocale,
  ...enLegalDocuments,
  lang: "Language",
  add: "Add",
  products: "Products",
  productsAdded: "Products Added",
  productAdded: "Product Added",
  waiterCalled: "The waiter has been called, please wait",
  problem: "A problem occurs, try again",
  notFound: "Oops! Page not found",
  notFoundText: "Seems like this page doesn't exist",
  menu: "Menu",
  getMeOut: "Get me out of here",
  productsNotFound: "Couldn't find any products!",
  store: "Store",
  myRequest: "Order",
  addNote: "Do you want to add any note to the request?",
  makeRequest: "Make Request",
  emptyCart: "Empty Cart",
  cartEmpty: "Cart Empty!",
  addProducts: "Add Products",
  successRequest: "Request added correctly",
  scanQrAgain: "You must scan a QR again",
  thankYou: "Thank You!",
  requestMade: "Your request has been made correctly.",
  returnToMenu: "Return to Menu",
  welcome: "Welcome!",
  scanQr:
    "Navigate through the options in the menu or scan the table's QR to see the menu",
  shoppingCart: "Shopping Cart",
  requestAdded: "Your request has been added",
  welcomeScan: "Welcome, scan a QR!",
  loading: "Loading...",
  callWaiter: "Call Waiter",
  languageNotAdded: "Sorry the system its not yet translated to this language",
  legalConcernTitle: "Legal Concern",
  cookiePolicyTitle: "Cookie Policy",
  privacyPolicyTitle: "Privacy Policy",
  outOfStock: "Out of stock",
  stockQty: "Quantity in stock",
};
