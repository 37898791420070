const initialState = {
  allowTips: false,
  images: { logo: null, header: null },
  minimalOrderDomicile: 0,
  styles: {
    primaryColor: null,
    bgPageColor: null,
    bgHeaderColor: null,
  },
  texts: {
    title: { en: "Welcome to Rustika", es: "Bienvenido a Rustika" },
    subtitle: { en: "Passion for what we do", es: "Pasión por lo que hacemos" },
    description: {
      en: "More than 10 years doing what we like, we are a house of meals with good product and a close attention. Where you can enjoy a tribute based on fresh fish as the protagonist",
      es: "Más de 10 años haciendo lo que nos gusta, somos una casa de comidas con buen producto y una atención cercana. Pruebas nuestros platos caseros y de calidad.",
    },
  },
  bookings: false,
  callWaiter: false,
  localOrders: false,
};
export const state = () => ({ settingDigitalLetter: { ...initialState } });
